import React, { useEffect, useState } from "react";
import axios from "axios";
import constantApi from "../../constantApi";
import { useCart } from "../../context/CartContext";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { useLocationContext } from "../../context/LocationContext";
import testbg from "../../Images/bggg.png"

const BloodTest = () => {
    const [tests, setTests] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { addToCart, cartItemIds } = useCart();
    const navigate = useNavigate();
    const { selectedLocation } = useLocationContext();

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/Blood Test`)
            .then((response) => {
                setTests(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error fetching blood test data:", error);
            });
    }, []);

    // Filter tests by location
    useEffect(() => {
        if (selectedLocation) {
            const filtered = tests.filter((test) =>
                test.location.some((loc) =>
                    loc.toLowerCase().includes(selectedLocation.toLowerCase())
                )
            );
            setFilteredTests(filtered);
        } else {
            setFilteredTests(tests);
        }
    }, [selectedLocation, tests]);

    const handleViewDetails = (test) => {
        navigate(`/Blood Test/${test._id}`, { state: { test } });
    };

    return (
        <div className="pb-4 pt-8 h-screen"
        // style={{
        //     backgroundImage: `url(${testbg})`
        // }}
        >
            <section>
                <div className="flex justify-between items-center">
                    <div className=" p-4 w-full rounded-lg">
                        <h2 className="lg:text-3xl md:text-xl sm:text-lg text-lg font-bold mb-2 text-gray-800">Blood Tests We Have</h2>
                        <p className="lg:text-lg md:text-md sm:text-sm text-sm font-semibold text-gray-800">
                            Explore Our Comprehensive Range of Tests
                        </p>
                    </div>
                </div>

                {/* Cards for each test */}
                {isLoading ? (
                    <div className="flex justify-center items-center lg:h-80 md:h-60 sm:h-60 h-60">
                        <Loader />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mx-4">
                        {filteredTests.length > 0 ? (
                            filteredTests.map((test) => (
                                <div key={test._id} className="w-full">
                                    <div
                                        onClick={() => handleViewDetails(test)}
                                        className="border border-gray-300 rounded-lg p-6 shadow-md hover:shadow-lg transition-all duration-200 ease-in-out cursor-pointer flex flex-col items-center justify-center space-y-4 bg-white"
                                    >
                                        <h3 className="text-lg font-semibold text-gray-700 text-center">
                                            {test.name}
                                        </h3>
                                        <p className="text-lg font-semibold text-black-600">
                                            Rs. {test.amount}
                                        </p>
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addToCart(test);
                                            }}
                                            className={`text-white py-2 px-6 text-sm rounded-md w-full ${cartItemIds.includes(test._id)
                                                ? "bg-green-600"
                                                : "bg-[#1b4d3e]"
                                                }`}
                                        >
                                            {cartItemIds.includes(test._id)
                                                ? "Added to Cart"
                                                : "Add to Cart"}
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-500 col-span-full">
                                No tests found for the selected location.
                            </p>
                        )}
                    </div>
                )}
            </section>
        </div>
    );
};

export default BloodTest;
