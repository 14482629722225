// AddressModal.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';

const AddressModal = ({
    isModalOpen,
    setIsModalOpen,
    userDetail,
    setUserDetail,
    isEditMode,
    setIsEditMode,
    selectedAddress
}) => {
    // Form state to store the data
    const [formData, setFormData] = useState({
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
    });

    // Set form data when in edit mode and a selected address is provided
    useEffect(() => {
        if (isEditMode && selectedAddress) {
            setFormData({
                streetAddress: selectedAddress.streetAddress,
                city: selectedAddress.city,
                state: selectedAddress.state,
                postalCode: selectedAddress.postalCode,
                country: selectedAddress.country,
            });
        }
    }, [isEditMode, selectedAddress]);

    // Handle input changes in the form
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const newAddress = { ...formData };
        const updatedUserDetail = { ...userDetail };
        if (isEditMode) {
            const index = userDetail.address.indexOf(selectedAddress);
            updatedUserDetail.address[index] = newAddress;
        } else {
            updatedUserDetail.address = [...userDetail.address, newAddress];
        }
        const token = Cookies.get('token');
        axios.put(`${constantApi.baseUrl}user/`, updatedUserDetail, {
            headers: { 'Authorization': token },
        })
            .then(() => {
                setUserDetail(updatedUserDetail);
                setIsModalOpen(false); // Close modal on success
                setIsEditMode(false); // Reset edit mode
            })
            .catch((err) => {
                console.error("Error updating address:", err);
            });
    };

    return (
        isModalOpen && (
            <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
                <div className="w-full max-w-3xl p-8 bg-white shadow-lg rounded-lg relative">
                    {/* Close Modal Button */}
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="absolute top-2 right-2 text-white bg-red-500 p-2 rounded-full hover:bg-red-700"
                    >
                        X
                    </button>

                    {/* Modal Title */}
                    <h2 className="text-3xl font-semibold text-center text-indigo-600 mb-8">
                        {isEditMode ? 'Edit Address' : 'Add New Address'}
                    </h2>

                    {/* Form */}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Street Address */}
                        <div>
                            <label htmlFor="streetAddress" className="block text-lg font-medium text-gray-700">Street Address</label>
                            <input
                                type="text"
                                id="streetAddress"
                                name="streetAddress"
                                value={formData.streetAddress}
                                onChange={handleChange}
                                required
                                className="w-full px-6 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                            />
                        </div>

                        {/* City */}
                        <div>
                            <label htmlFor="city" className="block text-lg font-medium text-gray-700">City</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                                className="w-full px-6 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                            />
                        </div>

                        {/* State */}
                        <div>
                            <label htmlFor="state" className="block text-lg font-medium text-gray-700">State</label>
                            <input
                                type="text"
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                required
                                className="w-full px-6 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                            />
                        </div>

                        {/* Postal Code */}
                        <div>
                            <label htmlFor="postalCode" className="block text-lg font-medium text-gray-700">Postal Code</label>
                            <input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                                required
                                className="w-full py-2  mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                            />
                        </div>

                        {/* Country */}
                        <div>
                            <label htmlFor="country" className="block text-lg font-medium text-gray-700">Country</label>
                            <input
                                type="text"
                                id="country"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                                required
                                className="w-full px-6 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="px-8 py-2 mt-1 text-lg font-semibold text-white bg-indigo-600 rounded-md shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
};

export default AddressModal;
