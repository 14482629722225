import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';
import drVideo from "../../Images/drVideo2.mp4";

const SignupForm = () => {
    const [mobile, setMobile] = useState("");
    const [isOtpVisible, setIsOtpVisible] = useState(false);
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false); // State for loader
    const countrycode = '+91';
    const navigate = useNavigate();

    const handleProceed = () => {
        if (mobile.length === 10) {
            setLoading(true); // Start the loader
            axios
                .post(`${constantApi.baseUrl}user/login`, {
                    phone: mobile,
                    countrycode: countrycode,
                })
                .then((response) => {
                    console.log('OTP sent successfully', response);
                    Swal.fire({
                        icon: 'success',
                        title: 'OTP Sent',
                        text: 'Please check your phone for the OTP.',
                    });
                    setIsOtpVisible(true); // Show OTP input field
                })
                .catch((error) => {
                    console.error("Error sending OTP:", error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed to Send OTP',
                        text: 'Please try again later.',
                    });
                })
                .finally(() => {
                    setLoading(false); // Stop the loader
                });
        } else {
            alert("Please enter a valid mobile number.");
        }
    };

    const handleVerifyOtp = () => {
        axios
            .post(`${constantApi.baseUrl}user/verify`, {
                phone: mobile,
                otp: otp,
                countrycode: countrycode,
            })
            .then((response) => {
                console.log('Response:', response.data.data.token);
                Cookies.set('token', response.data.data.token, { expires: 7, path: '' });

                Swal.fire({
                    icon: 'success',
                    title: 'OTP Verified',
                    text: 'Please complete your registration.',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                }).then(() => {
                    navigate("/mutualloginform");
                });
            })
            .catch((error) => {
                console.error("Error during OTP verification:", error);
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid OTP',
                    text: 'Please enter a valid OTP.',
                });
            });
    };

    return (
        <div className="flex justify-center items-center h-auto sm:h-[80vh] bg-gray-100">
            <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-xl sm:text-2xl font-bold text-gray-800 text-center">Sign Up</h2>
                <p className="text-gray-600 text-sm sm:text-base mb-6 text-center">
                    View your reports and upcoming health checkups at one place.
                </p>

                {/* Main Content Section */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div className="">
                        <video
                            src={drVideo}
                            alt="Animated Doctor"
                            className="w-full object-contain mb-4 rounded-lg"
                            autoPlay
                            loop
                            muted
                        />
                    </div>

                    {/* Right Form Section */}
                    <div className="">
                        {/* Mobile Number Input */}
                        {!isOtpVisible && (
                            <>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Mobile
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter your mobile number"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    className="w-full px-4 py-2 border border-green-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                                <p className="text-sm text-gray-500 mt-2">
                                    An OTP will be sent to this number.
                                </p>
                                <button
                                    onClick={handleProceed}
                                    disabled={loading} // Disable button when loading
                                    className={`mt-6 w-full text-white font-semibold py-2 rounded-lg ${loading ? "bg-green-900 cursor-not-allowed" : "bg-green-900 hover:bg-[#C19033]"
                                        } focus:outline-none`}
                                >
                                    {loading ? "Sending OTP..." : "Proceed"} {/* Show loader text */}
                                </button>
                                <div className="my-2">
                                    {/* Login Link */}
                                    <p className="text-sm text-gray-600 mt-6">
                                        Already have an account?
                                        <Link
                                            to="/loginform"
                                            className="text-blue-500 underline hover:text-blue-600"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                </div>
                            </>
                        )}

                        {/* OTP Input */}
                        {isOtpVisible && (
                            <>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Enter OTP
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    className="w-full px-4 py-2 border border-green-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                                />
                                <p className="text-sm text-gray-500 mt-2">
                                    Please enter the 4-digit OTP sent to <span>{mobile}</span>.
                                </p>
                                <button
                                    onClick={handleVerifyOtp}
                                    className="mt-6 w-full bg-green-900 text-white font-semibold py-2 rounded-lg hover:bg-[#C19033] focus:outline-none"
                                >
                                    Verify OTP
                                </button>
                            </>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SignupForm;
