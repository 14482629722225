import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="flex flex-wrap justify-between items-center text-center bg-[#f3ebdd] mb-16 py-8">
      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="lg:text-6xl md:text-xl sm:text-xl text-xl font-bold text-gray-800 mb-2">5+ </h2>
        <h2>Years of Experience</h2>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="lg:text-6xl md:text-xl sm:text-xl text-xl font-bold text-gray-800 mb-2">25+</h2>
        <p className="text-gray-600 text-sm md:text-base">Advanced Clinical Labs</p>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/5 px-4 mb-4 lg:mb-0">
        <h2 className="lg:text-6xl md:text-xl sm:text-xl text-xl font-bold text-gray-800 mb-2">1000+ </h2>
        <h2>Patient Touchpoints</h2>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/5 px-4">
        <h2 className="lg:text-6xl md:text-xl sm:text-xl text-xl font-bold text-gray-800 mb-2">4000+</h2>
        <h2>Comprehensive Tests & Profiles</h2>
      </div>

    </div>
  );
};

export default WhyChooseUs;
