import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import HealthCondition from './pages/header/heatlhCondition';
import HomeVisit from './pages/header/homeVisit';
import ConsultDoctor from './pages/ConsultDoctor/ConsultDoctor';
import Footer from './components/footer';
// Other Routes
import UserProfile from './pages/SignIn/UserProfile';
import UserSignInForm from './pages/SignIn/UserSignInForm';
import Specialty from './pages/header/Specialty';
import ManageAddress from './pages/SignIn/ManageAddress';
import Test from "../src/pages/Test"
import BottomNavbar from './components/BottomNavbar';
import { CartProvider } from './context/CartContext';
import FinalPayment from './pages/SignIn/FinalPayment';

//policy routes
import PrivacyPolicy from './policypages/PrivacyPolicy';
import RefundPolicy from './policypages/RefundPolicy';
import TermsAndConditions from './policypages/TermsAndConditions';
import AddressList from './pages/SignIn/AddressList';
import HealthConditionCollection from './pages/healthCondition/HealthConditionCollection';
import HealthPackage from './HomePageContent/HealthPackage';
import HealthRiskCollection from './pages/healthRisks/HealthRiskCollection';
import HealthPackageDetail from './pages/HealthPackage/HealthPackageDetail';

// import PopularTestDetail from './pages/PopularTest/PopularTestDetail';
import PopularTestCollection from './pages/PopularTest/PopularTestCollection';
import HealthRiskNavbarLink from './pages/header/HealthRiskNavbarLink';
import HealthPackageCollection from './pages/HealthPackage/HealthPackageCollection';
import BloodTest from './pages/header/BloodTest';
import { SearchProvider } from './context/SearchContext';
import { LocationProvider } from './context/LocationContext';
import UserOrder from './pages/SignIn/UserOrder';
import UserTestDetail from './pages/SignIn/UserTestDetail';
import Help_Feedback from './pages/Help_Feedback';
import ChooseDoctor from './pages/ConsultDoctor/ChooseDoctor';
import MyAppointment from './pages/ConsultDoctor/MyAppointment';
import LoginForm from './pages/SignIn/LoginForm';
import MutualLoginForm from './pages/SignIn/MutualLoginForm';
import SignupForm from './pages/SignIn/SignupForm';
import AboutUs from './components/Footer/AboutUs';
import MainHeader from './components/MainHeader';
import Career from './components/Footer/Career';
import Popup from './Popup';
// import MobileView from './components/MobileView';

function Layout() {
  return (
    <>
      <div className="relative">
        <MainHeader />
        <Header />
        <div className="min-h-screen">
          {/* <Popup /> */}
          <Outlet />
        </div>
        <BottomNavbar />
        <Footer />
      </div>
    </>
  );
}

const App = () => {
  return (
    <CartProvider>
      <SearchProvider>
        <LocationProvider>
          <div>
            <Routes>
              <Route element={<Layout />}>

                <Route path="/" element={<Home />} />
                <Route path="/health-packages" element={<HealthPackage />} />
                <Route path="/health-conditions" element={<HealthCondition />} />
                <Route path="/home-visit" element={<HomeVisit />} />
                <Route path="/specialty" element={<Specialty />} />

                {/* HealthRisk routes */}

                <Route path="/healthrisknavbarlink" element={<HealthRiskNavbarLink />} />
                <Route path="/:category" element={<HealthRiskCollection />} />
                <Route path="/:category/:id" element={<HealthPackageDetail />} />
                <Route path="/test/:name" element={<HealthConditionCollection />} />

                <Route path="/bloodTest" element={<BloodTest />} />
                <Route path="/populartestcollection" element={<PopularTestCollection />} />
                <Route path="/healthpackagecollection" element={<HealthPackageCollection />} />


                <Route path="/loginform" element={<LoginForm />} />
                <Route path="/signupform" element={<SignupForm />} />
                <Route path="/mutualloginform" element={<MutualLoginForm />} />
                <Route path="/userprofile" element={<UserProfile />} />
                <Route path="/userform" element={<UserSignInForm />} />
                <Route path="/manageaddress" element={<ManageAddress />} />
                <Route path="/userorder" element={<UserOrder />} />
                <Route path="/usertestDetail" element={<UserTestDetail />} />
                <Route path="/finalPayment" element={<FinalPayment />} />
                <Route path="/addressmodal" element={<AddressList />} />

                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/termsandconditions" element={<TermsAndConditions />} />
                <Route path="/help-feedback" element={<Help_Feedback />} />

                <Route path="/consult-doctor" element={<ConsultDoctor />} />
                <Route path="/choose-doctor" element={<ChooseDoctor />} />
                <Route path="/my-appointment" element={<MyAppointment />} />

                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/career" element={<Career />} />

                <Route path="/test" element={<Test />} />

              </Route>
            </Routes>
          </div>
        </LocationProvider>
      </SearchProvider>
    </CartProvider>
  );
};

export default App;
