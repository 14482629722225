import React, { createContext, useState, useContext, useEffect } from 'react';

const CartContext = createContext();
export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [selected, setSelected] = useState(false);
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const [cartItemIds, setCartItemIds] = useState(() => {
        // Load cart item IDs from localStorage, if they exist
        const savedCartItemIds = localStorage.getItem('cartItemIds');
        return savedCartItemIds ? JSON.parse(savedCartItemIds) : [];
    });

    const addToCart = (item) => {
        if (!cartItemIds.includes(item._id)) {
            setCart((prevCart) => {
                const updatedCart = [...prevCart, item];
                // Save to localStorage
                localStorage.setItem('cart', JSON.stringify(updatedCart));
                return updatedCart;
            });
            setCartItemIds((prevIds) => {
                const updatedIds = [...prevIds, item._id];
                // Save to localStorage
                localStorage.setItem('cartItemIds', JSON.stringify(updatedIds));
                return updatedIds;
            });
        } else {
            alert("Item is already in the cart.");
        }
    };

    const removeFromCart = (itemId) => {
        setCart((prevCart) => {
            const updatedCart = prevCart.filter(item => item._id !== itemId);
            // Save to localStorage
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            return updatedCart;
        });
        setCartItemIds((prevIds) => {
            const updatedIds = prevIds.filter(id => id !== itemId);
            // Save to localStorage
            localStorage.setItem('cartItemIds', JSON.stringify(updatedIds));
            return updatedIds;
        });
        setSelected(false);
    };

    const clearCart = () => {
        setCart([]);
        setCartItemIds([]);
        setSelected(false);
        // Clear localStorage
        localStorage.removeItem('cart');
        localStorage.removeItem('cartItemIds');
    };

    useEffect(() => {
        // Optional: Sync cart state with localStorage when cart changes
        // This is just a safety measure, since we're already saving on add/remove
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('cartItemIds', JSON.stringify(cartItemIds));
    }, [cart, cartItemIds]);

    return (
        <CartContext.Provider value={{ cart, cartItemIds, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
