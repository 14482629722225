import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import HeartTest from "../Images/HealthRiskImage/Heart.png"
import LiverTest from "../Images/HealthRiskImage/Liver.png"
import KidneyTest from "../Images/HealthRiskImage//Kidney.png"
import ThyroidTest from "../Images/HealthRiskImage//Thyroid.png"
import JointTest from "../Images/HealthRiskImage//Bone.png"
import { Link } from 'react-router-dom';
const HealthRisk = () => {
    const healthTest = [
        { id: 1, name: 'Heart Tests', img: HeartTest, route: "/Heart Test" },
        { id: 2, name: 'Liver Tests', img: LiverTest, route: "/Liver Test" },
        { id: 3, name: 'Kidney Tests', img: KidneyTest, route: "/Kidney Test" },
        { id: 4, name: 'Thyroid Tests', img: ThyroidTest, route: "/Thyroid Test" },
        { id: 4, name: 'Joint Tests', img: JointTest, route: "/Bone And Joint Test" },
    ]
    return (
        <div className='px-4 sm:px-8'>
            <section className="mb-8 ">
                <div className="flex justify-between items-center mb-6 ">
                    <div>
                        <h2 className="lg:text-xl md:text-xl sm:text-lg text-lg  font-bold mb-2 text-gray-800">Health Risk Tests</h2>
                        <p className="lg:text-md md:text-md sm:text-sm text-sm  font-semibold text-gray-800">Choose test as per your health risk</p>
                    </div>
                    <div className="lg:w-[10%] md:w-[10%] sm:w-[30%] w-[30%]">
                        <Link to="/healthrisknavbarlink" className="text-black">

                            <button className="border border-[#522f7c] text-[#522f7c] py-1 lg:px-4 md:px-2 sm:px-2 px-2 text-sm rounded-md hover:bg-purple-700 hover:text-white transition duration-200 ease-in-out">
                                View More
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Single row with smaller cards */}
                <div className="flex overflow-x-auto space-x-8 pb-4  scrollbar-hide">
                    {healthTest.map((test) => (
                        <div
                            // onClick={() => handleClick(test.name)}
                            key={test.id}
                            className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                        >
                            <Link to={test.route}>
                                <div className="flex flex-col items-center ">
                                    {/* Circular frame for image */}
                                    <div className="w-32 h-32 rounded-full overflow-hidden mb-4">
                                        <img
                                            src={test.img || "imgage not found"}
                                            alt={test.name}
                                            className="w-full h-full object-cover bg-[#f3ebdd] p-4"
                                        />
                                    </div>

                                    {/* Test Name */}
                                    <h3 className="text-m font-bold text-gray-700">{test.name}</h3>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default HealthRisk
