import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import constantApi from "../constantApi";
import { useCart } from "../context/CartContext";
import { useLocationContext } from "../context/LocationContext";
import Loader from "../pages/Loader";

const PopularTest = () => {
    const [tests, setTests] = useState([]);
    const [filteredTests, setFilteredTests] = useState([]);
    const { selectedLocation } = useLocationContext();
    const [isLoading, setIsLoading] = useState(true); // Loading state


    const navigate = useNavigate();
    const { addToCart, cartItemIds } = useCart();

    useEffect(() => {
        axios
            .get(`${constantApi.baseUrl}user/product/Popular Test`)
            .then((response) => {
                setTests(response.data.data);
                setIsLoading(false)
            })
            .catch((error) => {
                console.error("Error fetching tests: ", error);
            });
    }, []);

    // Filter tests whenever the location changes
    useEffect(() => {
        if (selectedLocation) {
            const filtered = tests.filter((test) =>
                test.location.some((loc) => loc.toLowerCase().includes(selectedLocation.toLowerCase()))
            );
            setFilteredTests(filtered);
        } else {
            setFilteredTests(tests);
        }
    }, [selectedLocation, tests]);

    const handleView = (testId) => {
        const selectedTest = tests.find(test => test._id === testId);
        navigate(`/populartest/${testId}`, { state: { test: selectedTest } });
    };

    return (
        <div className='px-4 sm:px-8'>
            <section className="mb-8">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="lg:text-xl md:text-xl sm:text-lg text-lg  font-bold mb-2 text-gray-800">Popular Test</h2>
                        <p className="lg:text-md md:text-md sm:text-sm text-sm  font-semibold text-gray-800">Choose from our frequently booked blood tests</p>
                    </div>


                    <div className="lg:w-[10%] md:w-[10%] sm:w-[30%] w-[30%]">
                        <button
                            className="border border-[#522f7c] text-[#522f7c] py-1 lg:px-4 md:px-2 sm:px-2 px-2 text-sm rounded-md hover:bg-purple-700 hover:text-white transition duration-200 ease-in-out"
                            onClick={() => navigate('/populartestcollection')}
                        >
                            View More
                        </button>
                    </div>
                </div>
                {
                    isLoading ? (
                        <div className="flex justify-center items-center h-auto">
                            <Loader />
                        </div>
                    ) : (
                        <div className="relative mx-2">
                            {/* Left Scroll Button */}
                            <button
                                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-[#1b4d3e] text-white p-3 rounded-full shadow hover:bg-[#6a3fad]  hidden sm:block"
                                onClick={() => {
                                    document.getElementById('populartestcollection').scrollBy({ left: -360, behavior: 'smooth' });
                                }}
                            >
                                <FaAngleLeft />
                            </button>

                            {/* Right Scroll Button */}
                            <button
                                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-[#1b4d3e] text-white p-3 rounded-full shadow hover:bg-[#ca8fde]  hidden sm:block"
                                onClick={() => {
                                    document.getElementById('populartestcollection').scrollBy({ left: 360, behavior: 'smooth' });
                                }}
                            >
                                <FaAngleRight />
                            </button>

                            {/* Scrollable container */}
                            <div
                                id="populartestcollection"
                                className={`flex ${filteredTests.length > 0 ? "overflow-x-auto space-x-8 pb-4" : "justify-center items-center h-full"} scrollbar-hide`}
                            >
                                {filteredTests.length > 0 ? (
                                    filteredTests.map((test) => (
                                        <div
                                            key={test._id}
                                            className="border-t-4 border-[#522f7c] p-6 shadow-lg rounded-lg hover:shadow-2xl transition duration-200 ease-in-out w-full cursor-pointer"
                                            onClick={() => handleView(test._id)}
                                        >
                                            <div className="flex flex-col items-center w-72 gap-4">
                                                <h3 className="text-lg font-bold text-gray-700">{test.name}</h3>
                                                <p className="font-bold">Rs.{test.amount}</p>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent navigation when clicking "Add to Cart"
                                                        addToCart(test);
                                                    }}
                                                    className={`text-white py-1 px-4 w-4/5 h-10 text-sm rounded-md ${cartItemIds.includes(test._id) ? "bg-green-600" : "bg-[#1b4d3e]"
                                                        }`}
                                                >
                                                    {cartItemIds.includes(test._id) ? "Added to Cart" : "Add to Cart"}
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="flex justify-center items-center h-full">
                                        <p className="text-center text-gray-500">
                                            No tests found for the selected location.
                                        </p>
                                    </div>
                                )}
                            </div>

                        </div>
                    )
                }


            </section>
        </div>
    );
};

export default PopularTest;
