import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";

const BottomNavbar = () => {
    const openWhatsApp = () => {
        const phoneNumber = "8595952501";
        const message = "Hi! I have a query about your services.";
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
        <div className="fixed bottom-0 left-0 w-full   text-white mx-auto">
            {/* Content for large devices */}
            <div className="hidden lg:flex justify-center gap-4 p-2 mx-auto space-y-2 items-center w-full bg-[#1b4d3e]">
                {/* Text Content */}
                <div className="text-center mt-2">
                    <h1 className="font-semibold lg:text-lg md:text-md sm:text-sm text-sm">
                        Do you have any queries?
                    </h1>
                </div>

                {/* Get a Call Back Button */}
                <div className="text-center">
                    <button className="bg-[#c19033] text-black font-semibold lg:text-lg md:text-md sm:text-sm text-sm px-4 rounded-md py-1">
                        Get a call back now!
                    </button>
                </div>

                {/* Call Us Now Text */}
                <div className="text-center">
                    <h1 className="font-semibold lg:text-lg md:text-md sm:text-sm text-sm">
                        or call us now at
                    </h1>
                </div>

                {/* Phone Number Button */}
                <div className="text-center">
                    <a href="tel:+8595952501">
                        <button className="bg-white font-semibold lg:text-lg md:text-md sm:text-sm text-sm text-black px-4 py-1 rounded-md">
                            +91 8595952501
                        </button>
                    </a>
                </div>
                <div>
                    <IoLogoWhatsapp onClick={openWhatsApp} className='text-4xl text-green-600 animate-pulse cursor-pointer ' />
                </div>
            </div>


            {/* Content for small devices */}
            <div className="flex flex-col lg:hidden justify-center p-2 items-center bg-[#1b4d3e]">
                <div>
                    <p className="text-lg font-semibold">Book a Home visit now</p>
                </div>
                <div>
                    <a href="tel:+9876543210">
                        <button className="bg-yellow-500 font-bold text-lg text-black px-4 py-1 rounded-md">
                            +91 8595952501
                        </button>
                    </a>
                </div>
            </div>



        </div>
    );
};

export default BottomNavbar;
