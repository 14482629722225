import axios from 'axios';
import React, { useEffect, useState } from 'react';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';
import { FaCalendarAlt, FaDollarSign, FaHeartbeat } from 'react-icons/fa'; // Added icons
import Loader from '../Loader';
import { Link } from 'react-router-dom';

const MyAppointment = () => {
    const [appointmentData, setAppointmentData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState("");

    useEffect(() => {
        const token = Cookies.get('token');
        axios
            .get(`${constantApi.baseUrl}user/consult`, {
                headers: {
                    Authorization: token,
                },
            })
            .then((response) => {
                setAppointmentData(response.data.data);
                setIsLoading(false)
                console.log("response is from my appointment ", response);
            })
            .catch((err) => {
                console.log('error ', err);
            });
    }, []);

    const handleLinkClick = (link) => {
        console.log("link is ", link);
        const formattedLink = link.startsWith("http") ? link : `https://${link}`;
        window.open(formattedLink, "_blank");
        console.log("Opening link:", formattedLink);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="bg-white min-h-screen w-full p-6">
            <h1 className="text-xl sm:text-xl md:text-xl lg:text-4xl font-bold text-center text-[#C19033] mb-8">
                My Appointments
            </h1>
            {isLoading ? (
                <div className="flex justify-center items-center lg:h-80 md:h-60 sm:h-60 h-60 ">
                    <Loader />
                </div>
            ) : (
                <div>
                    {appointmentData && appointmentData.length > 0 ? (
                        // Apply grid-cols-3 only to this div
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            {appointmentData.map((data, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-2xl shadow-xl p-6 text-black transform my-4"
                                >
                                    {/* Icons and Title */}
                                    <div className="flex items-center gap-4">
                                        <FaHeartbeat className="text-3xl" />
                                        <h2 className="text-2xl font-semibold">{data.speciality}</h2>
                                    </div>

                                    {/* Information Section */}
                                    <div className="space-y-4">
                                        <div className="flex justify-between items-center text-lg">
                                            <span className="font-semibold text-purple-500">Consult Status:</span>
                                            <span>{data.consultstatus}</span>
                                        </div>

                                        {/* <div className="flex justify-between items-center text-lg">
                                            <span className="font-semibold text-purple-500">Status:</span>
                                            <span>{data.status}</span>
                                        </div> */}

                                        <div className="flex justify-between items-center text-lg">
                                            <span className="font-semibold text-purple-500">Amount Paid:</span>
                                            <span>₹{data.amount}</span>
                                        </div>

                                        <div className="flex justify-between items-center text-lg">
                                            <span className="font-semibold text-purple-500">Booking Date:</span>
                                            <span>{new Date(data.createdAt).toLocaleDateString()}</span>
                                        </div>

                                        {data.consultstatus === "scheduled" && (
                                            <div>
                                                <div className="flex justify-between items-center text-lg">
                                                    <span className="font-semibold text-purple-500">Appt. scheduled At:</span>
                                                    <span>{data.startdate}</span>
                                                </div>
                                                <div className="flex justify-between items-center text-lg">
                                                    <span className="font-semibold text-purple-500">Appointment Link:</span>

                                                    <button
                                                        className='hover:text-blue-300'
                                                        onClick={() => handleLinkClick(data.videolink)}>
                                                        Video Link
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Icon for Date */}
                                    <h1>default value for it cosider and more things </h1>
                                    {/* <div className="flex justify-between text-sm text-gray-300 mt-4">
                                        <FaCalendarAlt className="text-xl" />
                                        <span>{new Date(data.createdAt).toLocaleTimeString()}</span>
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-lg text-gray-600 mt-6">
                            You have no appointments yet.{" "}
                            <Link to="/consult-doctor">
                                <span className="underline decoration-purple-500 text-xl font-bold hover:scale-105 duration-500">
                                    Book one now!
                                </span>
                            </Link>
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default MyAppointment;
