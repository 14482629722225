import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import booking from "../../Images/userDashboard_img/booking-New.png";
import report from "../../Images/userDashboard_img/report.png";
import manageAdd from "../../Images/userDashboard_img/manageAdd.png";
import help from "../../Images/userDashboard_img/help.png";
import axios from 'axios';
import Avatar from 'react-avatar';
import constantApi from '../../constantApi';
import Loader from '../Loader';
import Cookies from 'js-cookie';
import male from "../../Images/male.png"
import female from "../../Images/female.png"

const UserProfile = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [gender, setGender] = useState()
    const [userDetail, setUserDetail] = useState({
        countrycode: "",
        dob: "",
        email: "",
        name: "",
        phone: "",
        userId: ""
    });

    // Handle click on Edit Profile button
    const handleEdit = () => {
        navigate("/userform", { state: userDetail });
    };

    const handleManageAdd = () => {
        navigate("/ManageAddress", { state: userDetail });
    };

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            axios
                .get(`${constantApi.baseUrl}user/`, {
                    headers: {
                        "Authorization": token
                    }
                })
                .then((response) => {
                    console.log("response form user Profile ", response);
                    setGender(response.data.data.gender)

                    setUserDetail(response.data.data);
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log("error is ", err);
                });
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="container mx-auto p-4 px-4 md:px-20">
            <h1 className="text-2xl font-bold text-purple-500 mb-4 text-center">My Dashboard</h1>
            <hr className="border-t-2 border-purple-500 mx-auto w-1/5 mb-4" />

            {
                isLoading ? (
                    <div className="flex justify-center items-center h-auto">
                        <Loader />
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                        {/* User Profile Section */}
                        <div className="bg-purple-100 shadow rounded-md p-4 col-span-1">
                            <div className="flex justify-center gap-4 items-center">
                                <div className="">
                                    {/* <Avatar name={userDetail.name} /> */}
                                    <img
                                        src={gender === "Male" || "male" ? male : female}
                                        className="rounded-full lg:h-28 md:h-20 sm:h-16 h-12 lg:w-28 md:w-20 sm:w-16 w-12"
                                        alt={gender}
                                    />

                                </div>
                                <div className="flex flex-col mb-2">
                                    <div className="ml-4">
                                        <h2 className="text-xl font-bold">{userDetail.name || "Name"}</h2>
                                        <p className="text-gray-600">{userDetail.email || "Email"}</p>
                                        <p className="text-gray-600">
                                            {userDetail.countrycode && userDetail.phone
                                                ? `${userDetail.countrycode} ${userDetail.phone}`
                                                : "Phone number"}
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <div>
                                {userDetail.phone && (
                                    <div className="items-center flex justify-center mt-4">
                                        <button
                                            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md"
                                            onClick={handleEdit}
                                        >
                                            Edit Profile
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Other sections like "My Booking", "My Sample Tracking", etc. */}
                        <Link to="/userorder">
                            <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                                <img src={booking} alt="booking" className="h-32 w-32" />
                                <h2 className="text-xl font-bold mt-2">My Booking</h2>
                            </div>
                        </Link>


                        <Link to="/my-appointment">
                            <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                                <img src={report} alt="report" className="h-32 w-32" />
                                <h2 className="text-xl font-bold mt-2">My Appointment</h2>
                            </div>
                        </Link>

                        <div onClick={handleManageAdd}>
                            <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                                <img src={manageAdd} alt="manageAdd" className="h-32 w-32" />
                                <h2 className="text-xl font-bold mt-2">Manage Address</h2>
                            </div>
                        </div>

                        <Link to="/help-feedback">
                            <div className="shadow rounded-md p-4 flex flex-col items-center justify-center hover:scale-105 cursor-pointer duration-500 bg-white">
                                <img src={help} alt="help" className="h-32 w-32" />
                                <h2 className="text-xl font-bold mt-2">Help & Feedback</h2>
                            </div>
                        </Link>

                    </div>
                )}

        </div>

    );
};

export default UserProfile;
