import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import constantApi from '../../constantApi';
import Cookies from 'js-cookie';


const ManageAddress = () => {
    const location = useLocation();
    const userDetail = location.state;
    const navigate = useNavigate();

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [address, setAddress] = useState([]);
    const [formData, setFormData] = useState({
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        if (userDetail?.address?.length > 0) {
            setAddress(userDetail.address);
        }
    }, [userDetail]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = Cookies.get("token");
        const newAddress = {
            streetAddress: formData.streetAddress,
            city: formData.city,
            state: formData.state,
            postalCode: formData.postalCode,
            country: formData.country,
        };

        const updatedUserDetail = {
            ...userDetail,
            address: editIndex !== null
                ? address.map((addr, index) => index === editIndex ? newAddress : addr)
                : [...userDetail.address, newAddress],
        };

        console.log("updatedUserDetail", updatedUserDetail);

        axios.put(`${constantApi.baseUrl}user/`, updatedUserDetail, {
            headers: {
                "Authorization": token,
            }
        })
            .then((response) => {
                console.log("respinse from manage address", response);

                navigate("/userprofile");
                setIsModalOpen(false);
            })
            .catch((err) => {
                console.log("Error is ", err);
            });
    };

    const handleDelete = (index) => {
        const token = localStorage.getItem("token");
        const updatedAddressList = address.filter((_, i) => i !== index);

        const updatedUserDetail = {
            ...userDetail,
            address: updatedAddressList,
        };

        axios.put(`${constantApi.baseUrl}user/`, updatedUserDetail, {
            headers: {
                "Authorization": token,
            }
        })
            .then((response) => {
                setAddress(updatedAddressList);  // Update the local state after successful delete
            })
            .catch((err) => {
                console.log("Error is ", err);
            });
    };

    const handleEdit = (index) => {
        setEditIndex(index);
        setFormData(address[index]);
        setIsModalOpen(true);
    };

    const handleAddressSelect = (index) => {
        setSelectedAddress(index);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='gap-4 bg-gray-100'>
            <div className="mt-4 flex justify-end">
                <button
                    onClick={() => {
                        setEditIndex(null);
                        setIsModalOpen(true);
                    }}
                    className="px-8 py-3 text-lg font-semibold text-white bg-green-600 rounded-md shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
                >
                    + Add Address
                </button>
            </div>

            <div className="bg-gray-200 p-4 rounded-lg mt-4 flex flex-wrap justify-center gap-4 w-full">
                {address.length > 0 ? (
                    address.map((detail, index) => (
                        <div key={index} className="mb-4 bg-white p-4 rounded-lg border border-gray-300 w-full sm:w-80 md:w-96 lg:w-80 xl:w-96">
                            <div className="flex justify-between mb-4">
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id={`address-${index}`}
                                        name="address"
                                        value={index}
                                        checked={selectedAddress === index}
                                        onChange={() => handleAddressSelect(index)}
                                        className="mr-2"
                                    />
                                    <label htmlFor={`address-${index}`} className="font-semibold text-gray-700">
                                        Select this address
                                    </label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() => handleEdit(index)}
                                        className="text-blue-500 hover:text-blue-700"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>

                            <div>
                                <p><strong>City:</strong> {detail.city}</p>
                                <p><strong>Street:</strong> {detail.streetAddress}</p>
                                <p><strong>State:</strong> {detail.state}</p>
                                <p><strong>Postal Code:</strong> {detail.postalCode}</p>
                                <p><strong>Country:</strong> {detail.country}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-gray-600">No address found.</p>
                )}
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 z-50">
                    <div className="w-full max-w-3xl p-8 bg-white shadow-lg rounded-lg relative">
                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="absolute top-2 right-2 text-white bg-red-500 p-2 rounded-full hover:bg-red-700"
                        >
                            X
                        </button>

                        <h2 className="text-3xl font-semibold text-center text-indigo-600 mb-8">{editIndex !== null ? 'Edit Address' : 'Add New Address'}</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="streetAddress" className="block text-lg font-medium text-gray-700">Street Address</label>
                                <input
                                    type="text"
                                    id="streetAddress"
                                    name="streetAddress"
                                    value={formData.streetAddress}
                                    onChange={handleChange}
                                    required
                                    className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                />
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="city" className="block text-lg font-medium text-gray-700">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="state" className="block text-lg font-medium text-gray-700">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="postalCode" className="block text-lg font-medium text-gray-700">Postal Code</label>
                                    <input
                                        type="text"
                                        id="postalCode"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="country" className="block text-lg font-medium text-gray-700">Country</label>
                                    <input
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-6 py-3 mt-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="px-8 py-3 mt-4 text-lg font-semibold text-white bg-indigo-600 rounded-md shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageAddress;
